/* eslint-disable react/function-component-definition */
import { MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { subYears } from "date-fns";
import tw from "twin.macro"; // eslint-disable-line no-unused-vars

import {
  JimButton,
  JimDialog,
  TextButton,
} from "../components/util-components";
import { rightNow } from "../utils/global-consts";

const DeptSelectDialog = ({
  facilitiesArr,
  open,
  handleClose,
  deptList,
  setDeptList,
}) => {
  const [deptsObj, setDeptsObj] = useState({});
  const [facilitySel, setFacilitySel] = useState(facilitiesArr[0]);

  const handleFacilityChange = e => {
    facilitiesArr.map(fac => fac.id === e.target.value && setFacilitySel(fac));
  };

  useEffect(() => {
    const tempObj = {};
    for (let i = 0; i < facilitySel.departments.length; i += 1) {
      tempObj[facilitySel.departments[i].id] = false;
      if (deptList.includes(facilitySel.departments[i].id)) {
        tempObj[facilitySel.departments[i].id] = true;
      }
    }
    setDeptsObj(tempObj);
  }, []);

  const handleChange = e => {
    setDeptsObj({ ...deptsObj, [e.target.name]: e.target.checked });
  };

  const handleDone = () => {
    const finalArr = Object.keys(deptsObj).filter(jobID => deptsObj[jobID]);
    setDeptList(finalArr);
    handleClose();
  };

  return (
    <JimDialog
      open={open}
      handleClose={handleClose}
      tw="flex flex-col space-y-4 w-96"
    >
      <div tw="flex justify-between">
        <div tw="text-xl">Departments auswählen</div>
        <TextButton onClick={handleDone}>FERTIG</TextButton>
      </div>
      <TextField
        id="select-facility"
        value={facilitySel.id}
        label="Facility"
        select
        onChange={handleFacilityChange}
        variant="outlined"
      >
        {facilitiesArr[0] &&
          facilitiesArr.map(fac => (
            <MenuItem value={fac.id}>{fac.name}</MenuItem>
          ))}
      </TextField>
      {facilitySel.departments.map(dept => (
        <div>
          <label tw="flex items-center text-lg" htmlFor={dept.id}>
            <input
              type="checkbox"
              tw="mr-2"
              name={dept.id}
              id={dept.id}
              checked={deptsObj[dept.id]}
              onChange={handleChange}
            />
            {dept.name}
          </label>
        </div>
      ))}
    </JimDialog>
  );
};
DeptSelectDialog.propTypes = {
  facilitiesArr: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deptList: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setDeptList: PropTypes.func.isRequired,
};

const EditUserDialog = ({
  userData,
  open,
  handleClose,
  handleSubmit,
  facilitiesData,
}) => {
  const [formVars, setFormVars] = useState({
    ...userData,
    date_of_birth: new Date(parseInt(userData.date_of_birth, 10)),
  });
  const getDeptsIDs = () => {
    const idArr = [];
    userData.departments.map(dept => idArr.push(dept.id));
    return idArr;
  };
  const [deptList, setDeptList] = useState(getDeptsIDs());
  const [showFacilitiesDialog, setShowFacilitiesDialog] = useState(false);

  return (
    <>
      <JimDialog open={open} handleClose={handleClose} tw="max-w-max">
        <form
          tw="flex flex-col space-y-6 pt-6 max-w-md"
          id="edit-user-form"
          onSubmit={e => handleSubmit(e, formVars, deptList)}
        >
          <div tw="flex space-x-4">
            <TextField
              tw="w-1/2"
              id="first-name-input"
              value={formVars.first_name}
              label="Vorname"
              variant="outlined"
              onChange={e =>
                setFormVars({ ...formVars, first_name: e.target.value })
              }
            />
            <TextField
              tw="w-1/2"
              id="last-name-input"
              value={formVars.last_name}
              label="Nachname"
              variant="outlined"
              onChange={e =>
                setFormVars({ ...formVars, last_name: e.target.value })
              }
            />
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id="date-of-birth-input"
              renderInput={params => (
                <TextField label="Geburtsdatum" {...params} />
              )}
              value={formVars.date_of_birth}
              onChange={date =>
                setFormVars({ ...formVars, date_of_birth: date })
              }
              inputFormat="dd.MM.yyyy"
              minDate={subYears(rightNow, 99)}
              maxDate={subYears(rightNow, 16)}
              openTo="year"
            />
          </LocalizationProvider>
          <div tw="flex space-x-4">
            <TextField
              tw="w-3/4"
              id="street-input"
              value={formVars.address.street}
              label="Straße"
              variant="outlined"
              onChange={e =>
                setFormVars({
                  ...formVars,
                  address: { ...formVars.address, street: e.target.value },
                })
              }
            />
            <TextField
              tw="w-1/4"
              id="number-input"
              value={formVars.address.number}
              label="Nr."
              variant="outlined"
              onChange={e =>
                setFormVars({
                  ...formVars,
                  address: { ...formVars.address, number: e.target.value },
                })
              }
            />
          </div>
          <div tw="flex space-x-4">
            <TextField
              tw="w-2/5"
              id="zip-input"
              value={formVars.address.zip}
              label="PLZ"
              variant="outlined"
              onChange={e =>
                setFormVars({
                  ...formVars,
                  address: { ...formVars.address, zip: e.target.value },
                })
              }
            />
            <TextField
              tw="w-3/5"
              id="city-input"
              value={formVars.address.city}
              label="Stadt"
              variant="outlined"
              onChange={e =>
                setFormVars({
                  ...formVars,
                  address: { ...formVars.address, city: e.target.value },
                })
              }
            />
          </div>
          <TextField
            tw="w-full"
            id="email-input"
            value={formVars.email}
            label="E-Mail"
            variant="outlined"
            onChange={e => setFormVars({ ...formVars, email: e.target.value })}
          />
          <TextField
            tw="w-full"
            id="phone-input"
            value={formVars.phone}
            label="Telefon"
            variant="outlined"
            onChange={e => setFormVars({ ...formVars, phone: e.target.value })}
          />
          <TextField
            id="select-role"
            value={formVars.status}
            label="Status"
            select
            onChange={e => setFormVars({ ...formVars, status: e.target.value })}
            variant="outlined"
          >
            <MenuItem value="PENDING">PENDING</MenuItem>
            <MenuItem value="ACTIVE">ACTIVE</MenuItem>
            <MenuItem value="BANNED">BANNED</MenuItem>
          </TextField>
          <div tw="flex items-center space-x-8">
            <TextButton onClick={() => setShowFacilitiesDialog(true)}>
              Departments zuweisen
            </TextButton>
            <div>{`${deptList.length} Depts zugewiesen`}</div>
          </div>
          <JimButton variant="primary" type="submit" from="edit-user-form">
            speichern
          </JimButton>
        </form>
      </JimDialog>
      {facilitiesData && (
        <DeptSelectDialog
          facilitiesArr={facilitiesData.listFacilities}
          open={showFacilitiesDialog}
          handleClose={() => setShowFacilitiesDialog(false)}
          deptList={deptList}
          setDeptList={setDeptList}
        />
      )}
    </>
  );
};
EditUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    date_of_birth: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    picture: PropTypes.string,
    verification: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string,
      city: PropTypes.string,
      number: PropTypes.string,
      zip: PropTypes.string,
    }),
    tax_id: PropTypes.string,
    status: PropTypes.string.isRequired,
    subscribed_jobs: PropTypes.arrayOf(PropTypes.string),
    earnings: PropTypes.number,
    departments: PropTypes.arrayOf(PropTypes.string),
    expInstances: PropTypes.arrayOf(PropTypes.string),
    availabilities: PropTypes.arrayOf({}),
    pools: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  facilitiesData: PropTypes.shape({
    listFacilities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        status: PropTypes.string,
        departments: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      })
    ),
  }),
};
EditUserDialog.defaultProps = {
  userData: {},
  facilitiesData: {},
};

export default EditUserDialog;
