/* eslint-disable react/function-component-definition, no-underscore-dangle */
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import PropTypes from "prop-types";
import { identity, pickBy } from "lodash";

import EditUserDialog from "../components-admin/EditUserDialog";
import {
  JimButton,
  LoadingDialog,
  TilePrim,
} from "../components/util-components";
import withRoles from "../utils/with-roles";
import { PageTitleContext } from "../utils/pagetitle-provider";

const LIST_FACILITIES = gql`
  query ListFacilities {
    listFacilities {
      id
      name
      status
      departments {
        id
        name
      }
    }
  }
`;

const LIST_USERS = gql`
  query ListUsers(
    $last_name: String
    $first_name: String
    $date_of_birth: String
    $email: String
    $phone: String
    $address: AddressInput
    $status: String
  ) {
    listUsers(
      last_name: $last_name
      first_name: $first_name
      date_of_birth: $date_of_birth
      email: $email
      phone: $phone
      address: $address
      status: $status
    ) {
      id
      last_name
      first_name
      date_of_birth
      email
      phone
      picture
      verification
      address {
        city
        street
        number
        zip
      }
      tax_id
      status
      expInstances {
        id
      }
      availabilities {
        id
        start_time
        end_time
        instant_book
      }
      pools {
        id
      }
      roles
      facilities {
        id
      }
    }
  }
`;

const EDIT_USER_MUTATION = gql`
  mutation AdminEditUser(
    $id: ID
    $status: String
    $roles: [String]
    $last_name: String
    $first_name: String
    $date_of_birth: String
    $email: String
    $phone: String
    $picture: String
    $address: AddressInput
    $verification: String
    $tax_id: String
    $departments: [ID]
  ) {
    editUser(
      id: $id
      status: $status
      roles: $roles
      last_name: $last_name
      first_name: $first_name
      date_of_birth: $date_of_birth
      email: $email
      phone: $phone
      picture: $picture
      address: $address
      verification: $verification
      tax_id: $tax_id
      departments: $departments
    ) {
      id
      status
      roles
      last_name
      first_name
      date_of_birth
      email
      phone
      picture
      address {
        street
        city
        number
        zip
      }
      verification
      tax_id
    }
  }
`;

const InputsWrapper = tw.div`grid grid-cols-1 gap-2 md:(grid-cols-3 gap-4) lg:grid-cols-4 pt-2`;

const UserTile = ({ userData, facilitiesData }) => {
  const [editUser, { loading }] = useMutation(EDIT_USER_MUTATION);
  const [showDetails, setShowDetails] = useState(false);

  const handleSubmit = (e, formVars, deptList) => {
    e.preventDefault();
    const changedVars = {};

    const changedKeys = Object.keys(formVars).filter(
      key => formVars[key] !== userData[key]
    );
    // console.log("changedKeys", changedKeys)
    changedKeys.forEach(key => {
      changedVars[key] = formVars[key];
    });
    if (changedKeys.includes("address")) {
      delete changedVars.address.__typename;
    }
    if (
      formVars.date_of_birth.getTime().toString() === userData.date_of_birth
    ) {
      delete changedVars.date_of_birth;
    }
    if (deptList[0]) {
      changedVars.departments = deptList;
    }
    // console.log("changedVars", changedVars)

    editUser({
      variables: { ...changedVars, id: formVars.id },
    }).then(() => setShowDetails(false));
  };

  return (
    <>
      <TilePrim onClick={() => setShowDetails(true)} tw="cursor-pointer">
        <div tw="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          <div tw="font-semibold">{`${userData.first_name} ${userData.last_name}`}</div>
          <div>{userData.email}</div>
          <div>{`Status: ${userData.status}`}</div>
          <div>{`Roles: ${userData.roles.map(role => role)}`}</div>
        </div>
      </TilePrim>
      <EditUserDialog
        userData={userData}
        open={showDetails}
        handleClose={() => setShowDetails(false)}
        handleSubmit={handleSubmit}
        facilitiesData={facilitiesData}
      />
      <LoadingDialog open={loading} />
    </>
  );
};
UserTile.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    date_of_birth: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    picture: PropTypes.string,
    verification: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string,
      city: PropTypes.string,
      number: PropTypes.string,
      zip: PropTypes.string,
    }),
    tax_id: PropTypes.string,
    status: PropTypes.string.isRequired,
    subscribed_jobs: PropTypes.arrayOf(PropTypes.string),
    earnings: PropTypes.number,
    departments: PropTypes.arrayOf(PropTypes.string),
    expInstances: PropTypes.arrayOf(PropTypes.string),
    availabilities: PropTypes.arrayOf({}),
    pools: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  facilitiesData: PropTypes.shape({
    listFacilities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        status: PropTypes.string,
        departments: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      })
    ),
  }),
};
UserTile.defaultProps = {
  facilitiesData: {},
};

const ManageUsers = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Nutzer verwalten"), []);
  const [listUsers, { data: listUsersData, loading }] =
    useLazyQuery(LIST_USERS);
  const { data: listFacilitiesData } = useQuery(LIST_FACILITIES);
  const [searchParams, setSearchParams] = useState({
    last_name: "",
    first_name: "",
    date_of_birth: null,
    email: "",
    phone: "",
    city: "",
    status: "",
  });

  const handleSubmit = e => {
    e.preventDefault();
    const params = pickBy(searchParams, identity);
    listUsers({
      variables: params,
    });
  };

  return (
    <>
      <form id="list-users-form" onSubmit={handleSubmit}>
        <InputsWrapper>
          <TextField
            id="first-name-input"
            value={searchParams.first_name}
            label="Vorname"
            variant="outlined"
            onChange={e =>
              setSearchParams({ ...searchParams, first_name: e.target.value })
            }
          />
          <TextField
            id="last-name-input"
            value={searchParams.last_name}
            label="Nachname"
            variant="outlined"
            onChange={e =>
              setSearchParams({ ...searchParams, last_name: e.target.value })
            }
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id="date-of-birth-input"
              renderInput={params => (
                <TextField label="Geburtsdatum" {...params} />
              )}
              value={searchParams.date_of_birth}
              onChange={date =>
                setSearchParams({ ...searchParams, date_of_birth: date })
              }
              inputFormat="dd.MM.yyyy"
              openTo="year"
            />
          </LocalizationProvider>
          <TextField
            id="email-input"
            value={searchParams.email}
            label="E-Mail"
            variant="outlined"
            onChange={e =>
              setSearchParams({ ...searchParams, email: e.target.value })
            }
          />
          <TextField
            id="phone-input"
            value={searchParams.phone}
            label="Telefon"
            variant="outlined"
            onChange={e =>
              setSearchParams({ ...searchParams, phone: e.target.value })
            }
          />
          <TextField
            id="city-input"
            value={searchParams.city}
            label="Stadt"
            variant="outlined"
            onChange={e =>
              setSearchParams({ ...searchParams, city: e.target.value })
            }
          />
          <TextField
            id="tax-id-input"
            value={searchParams.tax_id}
            label="Steuer-ID"
            variant="outlined"
            onChange={e =>
              setSearchParams({ ...searchParams, tax_id: e.target.value })
            }
          />
          <TextField
            id="select-role"
            value={searchParams.status}
            label="Status"
            select
            onChange={e =>
              setSearchParams({ ...searchParams, status: e.target.value })
            }
            variant="outlined"
          >
            <MenuItem value="">alle</MenuItem>
            <MenuItem value="PENDING">PENDING</MenuItem>
            <MenuItem value="ACTIVE">ACTIVE</MenuItem>
            <MenuItem value="BANNED">BANNED</MenuItem>
          </TextField>
          <JimButton variant="primary" type="submit" tw="text-lg">
            suchen
          </JimButton>
        </InputsWrapper>
      </form>
      {listUsersData &&
        listFacilitiesData &&
        listUsersData.listUsers.map(user => (
          <UserTile
            key={user.id}
            userData={user}
            facilitiesData={listFacilitiesData}
          />
        ))}
      <LoadingDialog open={loading} />
    </>
  );
};

export default withRoles(ManageUsers, "ADMIN");
